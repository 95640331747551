<template>
  <div class="page-item-wrap">
    <main class="sub __login">
      <div class="inner __login-wrap">
        <div class="tit req-type">
          <h1>비밀번호 찾기</h1>
          <p class="stit">계정의 비밀번호를 잊으셨나요?</p>
        </div>
        <div class="sub-cont">
          <div class="link-tab">
            <a
              href="javascript:void(0)"
              class="link"
              :class="{ active: !isEmail }"
              @click="setTab('PHONE')"
            >
              휴대폰 번호로 찾기
            </a>
            <a
              href="javascript:void(0)"
              class="link"
              :class="{ active: isEmail }"
              @click="setTab('EMAIL')"
            >
              이메일 주소로 찾기
            </a>
          </div>
          <div class="login-form-box">
            <p class="t1">
              회원정보에 등록된 정보로 비밀번호를 찾을 수 있습니다.
            </p>
            <input
              v-model="userId"
              type="text"
              class="text"
              placeholder="아이디"
            />
            <input
              v-model="userNm"
              type="text"
              class="text"
              placeholder="이름"
            />
            <!-- 해당 컴포넌트에서 api 호출 -->
            <check-auth-num-form
              :isEmail="isEmail"
              :userNm="userNm"
              @submit="submit"
            ></check-auth-num-form>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import CheckAuthNumForm from 'Components/account/partials/CheckAuthNumForm'
import { getFindPwWithSms, getFindPwWithEmail } from 'Api/modules'

export default {
  components: {
    CheckAuthNumForm,
  },
  data() {
    return {
      isEmail: false,
      userId: '',
      userNm: '',
    }
  },
  methods: {
    setTab(name) {
      if (name === 'PHONE') this.isEmail = false
      else this.isEmail = true
      // 휴대폰 번호로 찾기/이메일 주소 찾기 탭 누를때마다 인풋창 초기화
      this.userNm = ''
      this.userId = ''
    },
    async submit({ certNo, certTargetValue }) {
      // let res
      try {
        if (this.isEmail) {
          // res = await getFindPwWithEmail({
          await getFindPwWithEmail({
            userId: this.userId,
            userNm: this.userNm,
            email: certTargetValue,
            certNo,
          })
        } else {
          // res = await getFindPwWithSms({
          await getFindPwWithSms({
            userId: this.userId,
            userNm: this.userNm,
            phone: certTargetValue,
            certNo,
          })
          // 확인창 띄울필요가 있다.
          if (this.isEmail) {
            this.$toasted.show(
              '임시 비밀번호를 이메일로 발급하였습니다. 확인 후 다시 로그인 해주세요.',
            )
          } else {
            this.$toasted.show(
              '임시 비밀번호를 휴대폰 SMS로 발급하였습니다. 확인 후 다시 로그인 해주세요.',
            )
          }
          this.$router.push('/login')
        }
      } catch (e) {
        console.error(e)
      }
    },
  },
}
</script>

<style></style>
