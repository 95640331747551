<template>
  <div>
    <div class="btn-input">
      <input
        v-model="certTargetValue"
        type="text"
        class="text"
        :placeholder="placeHolder"
        ref="certTargetValue"
      />
      <button type="button" @click="sendCertNo">{{ sendBtnText }}</button>
      <p v-if="isTimerValid">
        입력하신 {{ isEmail ? '이메일' : '휴대전화 번호' }}로 인증번호를
        발송하였습니다.
      </p>
    </div>
    <div v-if="isTimerValid" class="timer-box">
      <input
        v-model="certNo"
        type="text"
        class="text"
        placeholder="인증번호"
        @keyup.enter="submit"
      />
      <span class="timer">{{ `남은시간(${time})` }}</span>
    </div>
    <button type="button" class="btn __ok" @click="submit">인증확인</button>
  </div>
</template>

<script>
import { postSendSmsAuthNum, postSendEmailAuthNum } from 'Api/modules'

export default {
  props: {
    isEmail: {
      type: Boolean,
      default: false,
    },
    userNm: {
      type: String,
    },
  },
  data() {
    return {
      certTargetValue: '', //email 혹은 휴대폰 번호
      certNo: '',
      isTimerValid: false,
      intervalId: null,
      count: 180,
      time: '3:00',
      sendBtnText: '인증번호 발송',
    }
  },
  computed: {
    placeHolder() {
      if (this.isEmail) {
        return '이메일 주소'
      } else {
        return '휴대폰 번호'
      }
    },
  },
  watch: {
    // 휴대폰 번호로 찾기/ 이메일 주소로 찾기 토글될때마다 휴대폰/이메일 인풋창 초기화
    isEmail() {
      this.certTargetValue = ''
      clearInterval(this.intervalId)
      // this.count = 0
      this.count = 180
      this.time = '3:00'
      this.isTimerValid = false
    },
    certTargetValue() {
      if (!this.isEmail) {
        this.certTargetValue = this.certTargetValue.replace(/[^0-9]/g, '')
        // 만약 첫 세글자가 010 이 아니면, 삭제.
        if (this.certTargetValue.length === 1 && this.certTargetValue !== '0') {
          this.certTargetValue = ''
        } else if (
          this.certTargetValue.legnth === 2 &&
          this.certTargetValue !== '01'
        ) {
          this.certTargetValue = ''
        } else if (
          this.certTargetValue.length === 3 &&
          this.certTargetValue !== '010'
        ) {
          this.certTargetValue = ''
        } else if (this.certTargetValue.length > 11) {
          this.certTargetValue = this.certTargetValue.substr(0, 11)
        }
      }
    },
  },
  methods: {
    //타이머 시작
    startTimer() {
      if (this.intervalId) {
        clearInterval(this.intervalId)
        // this.count = 0
        this.count = 180
        this.time = '3:00'
      }

      this.isTimerValid = true
      this.intervalId = setInterval(() => {
        this.count--
        const min = Math.floor(this.count / 60)
        const sec =
          this.count % 60 < 10 ? `0${this.count % 60}` : this.count % 60
        this.time = `${min}:${sec}`

        //exit
        if (min === 0 && sec === '00') {
          clearInterval(this.intervalId)
          // 납은시간이 0 이되면 타이머 상태를 nonvalid 처리
          this.isTimerValid = false
        }
      }, 1000)
    },

    //인증번호 발송
    async sendCertNo() {
      if (this.userNm == '') {
        this.$toasted.error('이름을 입력해주세요.')
        return
      }
      try {
        let res
        if (this.isEmail) {
          if (this.certTargetValue == '') {
            this.$toasted.error('이메일을 입력해주세요.')
            this.$refs['certTargetValue'].focus()
            return
          }
          const email = new RegExp(/[-\d\S.+_]+@[-\d\S.+_]+\.[\S]{2,4}/)
          if (!email.test(this.certTargetValue)) {
            this.$toasted.error('이메일 형식이 올바르지 않습니다.')
            this.$refs['certTargetValue'].focus()
            return false
          }
          res = await postSendEmailAuthNum({
            userNm: this.userNm,
            email: this.certTargetValue,
          })
        } else {
          if (this.certTargetValue == '') {
            this.$toasted.error('휴대폰 번호를 입력해주세요.')
            this.$refs['certTargetValue'].focus()
            return
          }
          res = await postSendSmsAuthNum({
            userNm: this.userNm,
            phone: this.certTargetValue,
          })
        }
        if (res && res.status == 0)
          this.$toasted.show('인증번호가 발송되었습니다.')
        this.startTimer()
      } catch (e) {
        console.error(e)
      }
    },

    //인증확인
    submit() {
      //유효 시간, 인증번호 값 있고, 인증수단 주소 혹은 번호 기입 했을 경우
      if (!this.certNo && !this.certTargetValue) {
        this.$toasted.error('인증번호를 발송 받으시기 바랍니다.')
        return
      }
      if (!this.certTargetValue) {
        if (!this.isEmail) {
          this.$toasted.error('휴대폰 번호를 입력해주세요.')
          return
        } else {
          this.$toasted.error('이메일주소를 입력해주세요.')
          return
        }
      }
      if (!this.isTimerValid) {
        if (!this.certNo) {
          this.$toasted.error('인증번호를 발송 받으시기 바랍니다.')
          this.sendBtnText = '인증번호 발송'
        } else {
          this.$toasted.error('다시 인증번호를 발송 받으시기 바랍니다.')
          this.sendBtnText = '인증번호 재발송'
        }
        return
      }
      if (!this.certNo) {
        this.$toasted.error('인증번호를 입력해주세요.')
        return
      }
      if (this.certNo && this.certTargetValue) {
        this.$emit('submit', {
          certNo: this.certNo,
          certTargetValue: this.certTargetValue, //이메일 혹은 휴대폰 번호
        })
      }
    },
  },
  created() {
    // this.startTimer()
  },
}
</script>

<style></style>
